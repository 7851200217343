import Container from "@edenred/container";
import { MicroCopyContext } from "@edenred/micro-copy";
import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Card,
  Grid,
  Stack,
  SxProps,
  Typography,
  TypographyProps
} from "@mui/material";
import { graphql, navigate, PageProps } from "gatsby";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useRef } from "react";
import Button from "../components/Button";
import { APICardTypes } from "../components/CardType";
import { PageWithHeaderAndFooter } from "../components/Page";
import { Context } from "../components/Provider";
import { useToken, isBrowser } from "../hooks/useToken";
import { basketSummary } from "./checkout";

type Props = PageProps<
  Pick<GatsbyTypes.Query, "contentfulClient">,
  ERSTypes.PageContext
>;

const cardSx: SxProps = {
  px: 3,
  pt: { xs: 3, sm: 4 },
  pb: { xs: 3, sm: 5 },
  mb: { xs: 3, sm: 4 }
};

function DescriptionTerm({ children }: TypographyProps) {
  return (
    <Typography component="dt" variant="subtitle2" sx={{ mt: 2, mb: 0.5 }}>
      {children}
    </Typography>
  );
}

function DescriptionDetails({ children }: TypographyProps) {
  return (
    <Typography component="dd" variant="body2" sx={{ mb: { xs: 1.5, sm: 2 } }}>
      {children}
    </Typography>
  );
}

export default function CheckoutPage({ data, pageContext }: Props) {
  const { contentfulClient } = data;
  const { homePath } = pageContext;
  const [, setToken] = useToken();
  const getMicroCopy = useContext(MicroCopyContext);
  const {
    updateECodes,
    updateApiRetailer,
    updateRetailerLookup,
    updateCustomer,
    orderPageData
  } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();

  if (!orderPageData) {
    isBrowser &&
      enqueueSnackbar(getMicroCopy("general.no-order-data"), {
        variant: "warning"
      });
    isBrowser && navigate(homePath);
    return null;
  }

  useEffect(() => {
    setToken();
    updateECodes([]);
    updateRetailerLookup({});
    updateApiRetailer(null);
    updateCustomer(null);
  }, []);

  const { basket, orderData } = orderPageData;
  const cachedBasket = useRef(basket);

  const isSendByMail = orderData.items.some(
    (item) => item.cardType === APICardTypes.GIFTCARD
  );

  const isSendByEmail = orderData.items.some(
    (item) => item.cardType === APICardTypes.EGIFTCARD
  );

  const isCharity = orderData.items.some(
    (item) => item.cardType === APICardTypes.CHARITY
  );

  function handlePrintClick() {
    isBrowser && window.print();
  }

  return (
    <PageWithHeaderAndFooter
      title={getMicroCopy("order.title", { name: orderData.name })}
      description={getMicroCopy("order.description")}
      sx={{ backgroundColor: "grey.50" }}
      client={contentfulClient}
      homePath={homePath}
      isAddMoreCodesDisabled
      areChildrenHidden
    >
      <Container>
        <Stack
          alignItems="center"
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          sx={{ mt: { xs: 3, sm: 4 } }}
        >
          <CheckIcon sx={{ fontSize: "3.5rem" }} />
          <Typography component="h1" variant="h4">
            {getMicroCopy("order.title", { name: orderData.name })}
          </Typography>
          {contentfulClient?.survey && (
            <Button size="large" href={contentfulClient.survey.url}>
              {contentfulClient.survey.text}
            </Button>
          )}
        </Stack>
        <Grid
          container
          wrap="wrap-reverse"
          spacing={3}
          sx={{ mt: { xs: 1, sm: 2 }, mb: 6 }}
        >
          <Grid item xs={12} sm={7}>
            <Card variant="outlined" sx={cardSx}>
              <Typography component="h2" variant="h5" mb={3}>
                {getMicroCopy("order.order-confirmed")}
              </Typography>
              <Typography variant="body2" my={3}>
                {getMicroCopy("order.details-sent")} <b>{orderData.email}</b>
              </Typography>
              <Typography variant="body2" my={1}>
                {getMicroCopy("order.order-number", {
                  number: orderData.id
                })}
              </Typography>
              {/* <Typography variant="body2" my={1}>
                {getMicroCopy("order.order-date", {
                  date: orderData.date
                })}
              </Typography> */}
            </Card>
            <Card variant="outlined" sx={cardSx}>
              <Typography component="h2" variant="h5" mb={3}>
                {getMicroCopy("order.customer-details")}
              </Typography>
              <Box component="dl" sx={{ mt: 3, mb: 0 }}>
                <DescriptionTerm>
                  <b>{getMicroCopy("order.delivery-address")}</b>
                </DescriptionTerm>
                <DescriptionDetails>{orderData.email}</DescriptionDetails>
                {isSendByMail && (
                  <DescriptionDetails>
                    {orderData.name}
                    <br />
                    {orderData.address?.street}
                    <br />
                    {orderData.address?.town}
                    <br />
                    {orderData.address?.postcode}
                  </DescriptionDetails>
                )}
                <DescriptionTerm>
                  <b>{getMicroCopy("order.delivery-times.title")}</b>
                </DescriptionTerm>
                {isSendByEmail && (
                  <DescriptionDetails>
                    {getMicroCopy("order.delivery-times.email")}
                  </DescriptionDetails>
                )}
                {isSendByMail && (
                  <DescriptionDetails>
                    {getMicroCopy("order.delivery-times.mail")}
                  </DescriptionDetails>
                )}
                {isCharity && (
                  <DescriptionDetails>
                    {getMicroCopy("order.delivery-times.charity")}
                  </DescriptionDetails>
                )}
              </Box>
            </Card>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={3} my={3}>
              <Button
                fullWidth
                size="large"
                href={`${homePath}/`}
                variant="outlined"
              >
                {getMicroCopy("checkout.back-to-catalogue")}
              </Button>
              <Button
                fullWidth
                size="large"
                variant="outlined"
                onClick={handlePrintClick}
              >
                {getMicroCopy("checkout.print")}
              </Button>
            </Stack>
          </Grid>
          {cachedBasket.current.length > 0 && (
            <Grid item xs={12} sm={5}>
              {basketSummary(
                homePath,
                getMicroCopy("checkout.order-summary"),
                cachedBasket.current
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </PageWithHeaderAndFooter>
  );
}

export const pageQuery = graphql`
  query OrderPageQuery($id: String!) {
    contentfulClient(id: { eq: $id }) {
      ...Client
    }
  }
`;
